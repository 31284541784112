/* eslint-disable */ 
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../stores/index'
import HubView from '../views/HubView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect: to => {
    //   // the function receives the target route as the argument
    //   // we return a redirect path/location here.
    //   return { name: 'TenantView', params:{tenantId: 'default'   } }
    // },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/setup',
    name: 'Setup',
    component: () => import(/* webpackChunkName: "about" */ '../views/SetupView.vue')
  },
  {
    path: '/:tenantId',
    name: 'TenantView',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { name: 'TenantHub' }
    },
    
    component: () => import(/* webpackChunkName: "about" */ '../views/TenantView.vue'),
    children:[
      {
        path: 'hub',
        name: 'TenantHub',
        component: HubView,
        meta:{
          loginRequired:true
        }
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        name:"GenAITags",
        meta:{
          loginRequired:true
        },
        path: 'tags/:tag',
        component: () => import(/* webpackChunkName: "about" */ '../components/genai/GenAiTags.vue')
      },
      {
        path: 'gen-ai/:category',
        name: 'Generative AI',
        meta:{
          loginRequired:true
        },
        component:  () => import(/* webpackChunkName: "about" */ '../views/GenAiView.vue'),
        children: [
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            name:"GenAIChat",
            meta:{
              loginRequired:true
            },
            path: 'current-chat/:appId',
            component: () => import(/* webpackChunkName: "about" */ '../components/genai/GenAiChat.vue')
          },
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            name:"GenAIDocumentRequest",
            meta:{
              loginRequired:true
            },
            path: 'document-creator/:appId',
            component: () => import(/* webpackChunkName: "about" */ '../components/genai/GenAiDocument.vue')
          },
          {
            // UserPosts will be rendered inside User's <router-view>
            // when /user/:id/posts is matched
            name:"GenAILanding",
            meta:{
              loginRequired:true
            },
            path: '/',
            component: () => import(/* webpackChunkName: "about" */ '../components/genai/GenAi.vue')
          },
          
          
        ]
      },
      {
        path: 'login',
        name: 'Login',
        meta:{
          loginRequired:false
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
      },
      {
        path: 'settings',
        name: 'Tenant Settings',
        meta:{
          loginRequired:true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../components/admin/tenants/TenantsEdit.vue')
      },

      {
        name:"MySettings",
        path: 'my',
        component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminView.vue'),
        redirect: { name: "MyTenants" },
        meta:{
          loginRequired:true
        },
        children:[
        {
        name:"MyTenants",
        path: 'tenants',
        meta:{
          loginRequired:true
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/admin/tenants/TenantsHome.vue'),
        
      }
        ]
        
      }

    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    meta:{
      loginRequired:true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminView.vue'),
    redirect: { name: "AdminHome" },
    children: [
      {
        name:"AdminHome",
        path: 'home',
        meta:{
          loginRequired:true
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/admin/AdminHome.vue'),
        
      },
      {
        name:"TenantsHome",
        path: 'tenants',
        meta:{
          loginRequired:true
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/admin/tenants/TenantsHome.vue'),
        children :[
          {
            meta:{
              loginRequired:true
            },
            name:"EditTenant",
            path: ':tenantId',
            component: () => import(/* webpackChunkName: "about" */ '../components/admin/tenants/TenantsEdit.vue')
          }
        ]
      },
      {
        name:"DemoUsersHome",
        path: 'demousers',
        meta:{
          loginRequired:true
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/admin/demousers/DemoUsersHome.vue'),
        children :[
          {
            meta:{
              loginRequired:true
            },
            name:"DemoUserCreate",
            path: ':demo-user-create',
            component: () => import(/* webpackChunkName: "about" */ '../components/admin/demousers/DemoUserCreate.vue')
          }
        ]
      }
    ]
  },



  { 
    path: '/:pathMatch(.*)*', 
    name: 'Default',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/overview' }
    }
    
    
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {

  if (to.meta.loginRequired && !store.getters["user/getLoginUser"].name   ) {
      // You can use store variable here to access globalError or commit mutation
      var q = to.query
      q.forwardTo = to.fullPath 
      next({name: "Login", params:{tenantId: to.params.tenantId} , query: q})
  } else {
      
      next()
  }
})

export default router
