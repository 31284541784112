const generalSetting = {
  namespaced: true,
  state: () => ({
 
    customerName:"travelport",
    customerDisplayName: "GenAI Demo",
    displayLogo: "AyCompanyLogoUrl",
    displayState: "TEXT",
    apiUrl:"https://snc35vykm3.execute-api.us-east-1.amazonaws.com/Dev/qs-api",
    theme:{
      "primaryColor":"#07a649",
      "secondaryColor":"",
      "layoutColor":"",
      "dark": false
    }

  }),
  mutations: { 
    
    
    
  },
  actions: { 
    updateCustomerSetting ({ state },pGeneralSetting) {
     state.overview = pGeneralSetting.overview
     state.customerName=pGeneralSetting.customerName
     state.customerDisplayName=pGeneralSetting.customerDisplayName
     state.displayLogo=pGeneralSetting.displayLogo
     state.displayState = pGeneralSetting.displayState
     state.apiUrl = pGeneralSetting.apiUrl
     state.namedApiUrl = pGeneralSetting.namedApiUrl
     state.theme = pGeneralSetting.theme
     state.actionColor= pGeneralSetting.actionColor,
     state.subscriptionPlans = pGeneralSetting.subscriptionPlans
     state.referenceArchitecture = pGeneralSetting.referenceArchitecture
     state.hubTitle = pGeneralSetting.hubTitle
     state.hubDescription =  pGeneralSetting.hubDescription,
     state.productSettings = pGeneralSetting.productSettings
     state.genAiSettings = pGeneralSetting.genAiSettings
     state.fileUpload = pGeneralSetting.fileUpload
     state.splashScreen = pGeneralSetting.splashScreen
     state.banner = pGeneralSetting.banner
     state.platformInput = pGeneralSetting.platformParameters
     state.premiumTags = pGeneralSetting.premiumTags
     
     
    }
  },
  getters: { 
    getGeneralSetting: (state)=>{
      return {
        overview: state.overview,
        customerName : state.customerName,
        customerDisplayName:state.customerDisplayName,
        displayLogo:state.displayLogo,
        displayState:state.displayState,
        apiUrl:state.apiUrl,
        namedApiUrl: state.namedApiUrl,
        theme: state.theme,
        actionColor: state.actionColor,
        subscriptionPlans : state.subscriptionPlans,
        referenceArchitecture : state.referenceArchitecture,
        hubTitle : state.hubTitle,
        hubDescription : state.hubDescription,
        productSettings : state.productSettings,
        genAiSettings : state.genAiSettings,
        fileUpload : state.fileUpload,
        splashScreen: state.splashScreen,
        banner : state.banner,
        platformInput: state.platformInput,
        premiumTags : state.premiumTags
      }
    }
  }
}

export default generalSetting;