<template>
  <v-container   >
            <v-row class=" d-flex justify-center ma-xl-12 ma-lg-4 ma-4" >
                <v-col 
                  key="1r"
                  
                  cols="12"
                  sm="12"
                  md="9"
                  lg="12"
                  xl="7"
                  
                >
                  <!-- <span class="text-h5 secondary--text">{{getGeneralSetting.hubTitle}}</span> -->
                     
                      <v-sheet class="text-center text-h6 text-md-h5  font-weight-light " v-if="getGeneralSetting.hubDescription!= null">{{getGeneralSetting.hubDescription}}</v-sheet> 
                   
                    
                </v-col>
               
            </v-row>
            <v-row class=" d-flex justify-center " >
              <v-col cols="12" lg="6"   >
                 <v-text-field
                  v-model="searchText"
                    dense
                    outlined
                    label="Search apps"
                    prepend-inner-icon="mdi-magnify"
                    :append-icon="searchText.length > 0 ? 'mdi-close' : ''"
                    @click:append="searchText=''"
                  ></v-text-field>

              </v-col>
            </v-row>

            <v-row class="d-flex justify-center mt-n6" v-if="searchText.length>3">
              <v-col cols="12" lg="6" style="height:300px;overflow-y:scroll">
                <v-list two-line v-if="searchResults.length>0">
                  <template v-for="(result,index) in searchResults" >
                  <v-list-item @click="launchApp(result)" :key="result.productId">
                    <v-list-item-content>
                    <v-list-item-title class="text--primary font-weight-bold" v-text="result.productDisplayName"></v-list-item-title>
                    <!-- <v-list-item-subtitle v-text="result.pro">
                    </v-list-item-subtitle> -->
                      <v-list-item-subtitle v-text="result.matchText"
                        ></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon  color="primary" >mdi-navigation-variant-outline</v-icon>
                    </v-list-item-action>

                  </v-list-item>
                  <v-divider
                    v-if="index < searchResults.length - 1"
                    :key="index"
                  ></v-divider>
          </template>
                </v-list>
                <h5 v-else> No results</h5>   
              </v-col>
            </v-row>

              
              
         
             <TransitionGroup v-else   mode="out-in" :name="transition" tag="div"  style="overflow:hidden" class="row d-flex flex-row panel mt-lg-2  d-flex justify-center" >
              
                <v-col :key="tag"  v-for="tag in getGeneralSetting.premiumTags.split(',')">

              <v-card  
              flat
              class="panelcard mt-0 pt-0 primary lighten-4"  
              
              >
                
          <v-container >
            <v-row >
              <v-col class= "paneltop" cols="12" >
                    <v-list-item class="ma-0 pa-0 " two-line>
                      <v-list-item-content>
                        
                        <div class="text-h6 font-weight-light  ">
                          {{tag}}
                        </div>
                       
                      </v-list-item-content>

                      <v-list-item-avatar
                     
                        class="mr-2"
                        size="80"
                        color="grey"
                      >
                       <v-img 
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZh6xdRPTPuFOgp76Z5hASgHHz2i0wFvQNJQ&s"
                  class="body-2 black--text align-end"
                  elevated
                  height="80"
                  width="80"
                >
                  
         
                  
               
                   
                </v-img>
                      
                      </v-list-item-avatar>
                    </v-list-item>
              </v-col>
             
              <v-col cols="12" class="panelcard" style="height:230px;overflow-y:hidden;">
               
                <v-list
                    
                    dense
                    class="panelcard primary lighten-4"
                  >
                    <v-list-item-group  

                      color="primary"
                    >
                        <v-list-item  class="productitem mb-0 pb-0 pa-0 ma-0 " @click="launchApp(p)"
                             v-for="p in productsWithTag(tag)" :key="p.productId"
                             
                            >
                              <v-list-item-icon class="mr-2 ">
                                <v-icon :color="p.productStatus=='active'? 'success': 'grey darken-3'" >mdi-circle-medium</v-icon>
                              </v-list-item-icon>

                              <v-list-item-content  class="border text-subtitle-1 border-t-lg">
                                <v-list-item-title class="text-subtitle-2">{{p.productDisplayName}} </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-icon>
                                <v-icon small color="primary lighten-3" >mdi-navigation-variant-outline</v-icon>
                              </v-list-item-icon>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>
              </v-col>
              
            </v-row>
          </v-container>
                <v-card-actions >
                  <v-btn  
                  v-on:click="launchTag(tag)"
                  
                  depressed
                  text
                  :color="getGeneralSetting.actionColor"
                  class="text-leftx"
                >
               
                  show all
                </v-btn>
                
            </v-card-actions>
              </v-card>
       

                </v-col>
                <v-col
                
                v-for="product in currentPageProducts" 
                  :key= "product.templateId"
                  xl="2"
                  lg="3"
                  md="6"
                  sm="10"
                  cols="12"
                  class="mt-0 pa-2 "
                   
                  
                >
            
              <v-card  
              flat
              class="panelcard mt-0 pt-0"  
              v-if="product.productStatus=='active'"
                  
                   >
                
          <v-container >
            <v-row >
              <v-col class= "paneltop" cols="12" >
                    <v-list-item class="ma-0 pa-0" two-line>
                      <v-list-item-content>
                        
                        <div class="text-h6 font-weight-light ">
                          {{product.productDisplayName}}
                        </div>
                       
                      </v-list-item-content>

                      <v-list-item-avatar
                     
                        class="mr-2"
                        size="80"
                        color="grey"
                      >
                       <v-img 
                  :src="product.productDisplayImage"
                  class="body-2 black--text align-end"
                  elevated
                  height="80"
                  width="80"
                >
                  
         
                  
               
                   
                </v-img>
                      
                      </v-list-item-avatar>
                    </v-list-item>
              </v-col>
              <!-- <v-col cols="12" class="d-flex justify-center ">
       <v-avatar class="mt-3"
                color="primary"
                size="100"
              >
                <v-img 
                  :src="product.productDisplayImage"
                  class="body-2 black--text align-end"
                  elevated
                  height="100"
                  width="100"
                >
                  
         
                  
               
                   
                </v-img>
       </v-avatar>
              </v-col>
              <v-col cols="12" class="d-flex justify-center ">
                <h4>{{product.productDisplayName}}</h4>
              </v-col> -->
              <v-col cols="12" class="panelcard" style="height:230px;overflow-y:hidden">
                <!-- <ul style="list-style-type: none;">
                  <li v-for="p in productsInGroup(product.templateId)" :key="p.productId">
                    
                    <span class="text-subtitle-2 "> 
                    {{p.productDisplayName}} </span>
                  </li>
                </ul> -->
                <v-list
                    
                    dense
                    class="panelcard"
                  >
                    <v-list-item-group
                 
                      color="primary"
                    >
                        <v-list-item  class="productitem mb-0 pb-0 pa-0 ma-0" @click="launchApp(p)"
                             v-for="p in productsInGroup(product.templateId)" :key="p.productId"
                             
                            >
                              <v-list-item-icon class="mr-2 ">
                                <v-icon :color="p.productStatus=='active'? 'success': 'grey darken-3'" >mdi-circle-medium</v-icon>
                              </v-list-item-icon>

                              <v-list-item-content  class="border text-subtitle-1 border-t-lg">
                                <v-list-item-title class="text-subtitle-2">{{p.productDisplayName}} </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-icon>
                                <v-icon small color="primary lighten-3" >mdi-navigation-variant-outline</v-icon>
                              </v-list-item-icon>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>
              </v-col>
              
            </v-row>
          </v-container>
                <v-card-actions >
                  <v-btn v-if="product.subscriptionStatus=='active'" 
                  v-on:click="launch(product)"
                  
                  depressed
                  text
                  :color="getGeneralSetting.actionColor"
                  class="text-leftx"
                >
                  <!-- {{getGeneralSetting.productSettings.launchText}} -->
                  show all
                </v-btn>
                <v-btn v-if="product.subscriptionStatus=='inactive'" 
                  block
                  depressed
                  :color="getGeneralSetting.actionColor"
                  @click="subscribe(product)"
                >
                  {{getGeneralSetting.productSettings.subscribeText}}
                </v-btn>
            </v-card-actions>
              </v-card>
       
                </v-col>
             </TransitionGroup>

                         <v-row class="ma-0 pa-0 ">
              <v-col cols="12" class="ma-0 pa-0 d-flex flex-row justify-end"  v-if="totalPages>1">
                <v-btn :disabled="currentPage==0" @click="currentPage--;transition='slide-fadelr'" color="primary" icon><v-icon >mdi-menu-left</v-icon></v-btn>
                <v-btn :disabled="currentPage+1 == totalPages"  @click="currentPage++;transition='slide-faderl'" color="primary" icon><v-icon>mdi-menu-right</v-icon></v-btn>
                <!-- <v-btn :key="i" v-for="i in totalPages" @click="currentPage=i-1">  {{i}}</v-btn> -->
              </v-col>
            </v-row>
            
  
          
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {API} from 'aws-amplify'
  export default {
    name: 'HubComponent',

    data: () => ({
      currentPage:0,
      currentPageProducts:[],
      transition:'slide-faderl',
      searchText:''
      
    }),
    watch:{
      currentPage (){
       
          this.updateCurrentPageProducts()
        
      },
      getView(){
        this.$forceUpdate();
        this.updateCurrentPageProducts()
      }
    },
    mounted(){
      // if (this.getLoginUser.subscriptionPlan!=null){
      //   this.updateSubscriptionStatus({id:"analytics", status:"active"})
        
      // }
      // else{
      //   this.updateSubscriptionStatus({id:"analytics", status:"inactive"})
      // }
      const params = new URLSearchParams(window.location.search)
                      
                      var viewId=null
                      if (params.has("viewId")){
                        viewId=params.get("viewId") 
                      }
                    
    if (viewId != null){
      this.getViewDetails (viewId)
    }
      this.updateBreadCrumb({text:'Home', disabled:false, href:'#'})
      this.updateCurrentPageProducts()
    },
    computed:{
      ...mapGetters('appSetting', [
      'getCustomerProducts'
    ]),
      ...mapGetters('generalSetting', [
      'getGeneralSetting'
    ]),
    ...mapGetters('user', [
      'getLoginUser'
    ]),
    ...mapGetters('navigation', [
      'getView'
    ]),
    searchResults(){
      if (this.searchText.length<=3) return []
      var pList=[]
      var st=this.searchText.toLocaleLowerCase()
      this.getGeneralSetting.genAiSettings.products.forEach(p=>{
        var np = JSON.parse(JSON.stringify(p))
        var dI= np.productDisplayDescription.toLowerCase().indexOf(st)
      if (dI!=-1){
        np.matchText = "..." + np.productDisplayDescription.substring(dI,np.productDisplayDescription.length-1 > dI+ 40? dI+40 : np.productDisplayDescription.length-1  )

      }
      else{
        np.matchText= np.productDisplayDescription  && np.productDisplayDescription.trim()!= "" ? np.productDisplayDescription : "No description available"
      }
      
        pList.push(np)
      })
      
      const filterValue = this.searchText.toLowerCase()

    const filter = p => 
        p.productStatus== 'active' && (p.productDisplayName.toLowerCase().includes(filterValue) ||
        p.productDisplayDescription.toLowerCase().includes(filterValue) )




    return pList.filter(filter)

    },
    totalPages (){

      
      if (!this.activeProducts || this.activeProducts.length==0) return 0
      // alert("Total Pages:" + Math.ceil(this.activeProducts.length / this.itemsPerPage))
     return Math.ceil(this.activeProducts.length / this.itemsPerPage)
    },
    itemsPerPage () {
      var self=this
        // alert(self.$vuetify.breakpoint.name)
        var i=4
        switch (self.$vuetify.breakpoint.name) {
          case 'xs': i= 20;break;
          case 'sm': i= 20;break;
          case 'md': i= 20;break;
          case 'lg': i= 20;break;
          case 'xl': i= 20;break;
        }
        // alert(i + ": " + self.$vuetify.breakpoint.name) 
        return i
      },
  
    activeProducts(){
      const result = this.getCustomerProducts.filter(isActive);

      function isActive(product) {
        return product.productStatus  == 'active';
      }
      return result
    },

    comingsoonProducts(){
      const result = this.getCustomerProducts.filter(isComingSoon);
  
      function isComingSoon(product) {
        return product.productStatus  == 'comingsoon';
      }
      return result
    }
    },
     methods:{
       ...mapActions('appSetting', ['updateSubscriptionStatus']),
        ...mapActions('user', ['addNewGenAiChat']),
         ...mapActions('navigation', ['updateView', 'setUserProfileVisibility', 'setGenAiChatVisibility', 'setShowMessage','setMessage']),
        getViewDetails(viewId){
        var self=this
        self.setShowMessage (true)
              self.setMessage({type:'processing',
              text: 'Getting view details' 
              })
              var v={}
              // var v = JSON.parse(JSON.stringify(this.activeView))
        v.tenantId = this.$route.params.tenantId 
        v.userId= this.getLoginUser.userId
        v.viewId = viewId
        API.post("genAIUser","/" + this.$route.params.tenantId +"/my/views"+ "?action=getMyView&tenantId=" + this.$route.params.tenantId  +"&token=" + this.getLoginUser.accessToken ,
        {body: v}
              

        
        ).then((r)=> {
                    
                self.setShowMessage (false)
              self.setMessage({type:'success',
              text: 'View fetched successfully' 
              })
              r.Items[0].apps = JSON.parse(r.Items[0].apps)
              self.updateView ( r.Items[0])


                })
                .catch((error) => {
                        self.setShowMessage (true)
                        self.setMessage({type:'error',
                        text: 'Unable to fetch the view' + error 
                        })

                  
                  })

      },
      updateCurrentPageProducts (){
        var self=this
        while (this.currentPageProducts.length > 0)
        this.currentPageProducts.pop()
        this.activeProducts.forEach(function(product, index) {
            if (Math.floor((index) / self.itemsPerPage) == self.currentPage )
            {
              // alert(this.productsInGroup(product.templateId))
              // alert(product.productDisplayName  + self.productsInGroup(product.templateId).length  )
              if (self.productsInGroup(product.templateId).length>0)
                self.currentPageProducts.push(product)
            }
        })

      // var self=this
      
      // var i = this.itemsPerPage
      // var result=[]
      // this.activeProducts.forEach(function(product, index){
      //   // alert("Index+1" + (index+1) + ",  i: " + i + "  quotient:(index+1)/ i:  "  +  Math.floor(index+1 / i)  +  "    in currentPage: "  + self.currentPage)  
      //   if (Math.floor((index) / i) == self.currentPage ) result.push(product)
      // })
      // return result

    },
          addNewGenAIChattoUser(product){
        var chat = {
        "name": "New chat",
        "type": product.productId,
        "messages":[]
          
        }
        if (product.welcomeMessages!= null){
        product.welcomeMessages.forEach(message =>{
          var cMessage = {}
          if (message.renderType=='text'){
            message.content = message.content.replaceAll('%app%',product.productDisplayName)
          }
          cMessage = message
          cMessage.from = product.templateId
          cMessage.time = new Date(),
          chat.messages.push(cMessage)
          
          
        })}
        this.addNewGenAiChat(chat)
        this.$router.push({"name": "GenAIChat", "params": {"category":product.productGroupId, "appId":product.productId }})
        

        
      },
      launchApp(app){
        if (app.productStatus!= "active") return
        switch(app.templateId){
        
        case 'contentAccelerator':
          this.addNewGenAIChattoUser(app);
          break;
        case 'documentGenerator':
          this.$router.push({"name": "GenAIDocumentRequest", "params":{"category":app.productGroupId,"appId": app.productId}})
          break;
        default:
          break
        
          
        
          
          
        }
      },
    launch(product){
      
        this.$router.push({name: 'GenAILanding',params: {category: product.templateId }})
      
    },
    launchTag(tag){
      
        this.$router.push({name: 'GenAITags',params: {tag:tag }})
      
    },
    ...mapActions('navigation', ['setShowCardPayment','updateBreadCrumb']),
    productsInGroup(productGroupId){
    var apps=[]
    if (this.getView){
      apps= this.getView.apps
    }
              
              

      const result = this.getGeneralSetting.genAiSettings.products.filter(productOf(productGroupId,apps));
   
      function productOf(productGroupId,apps) {
        
          return function(product) {
            
            if (apps.length==0)
           
            return product.productGroupId  == productGroupId;

            else{
              var isInView = false
              for (var i=0;i<apps.length;i++){
                if (apps[i]== product.productId ){
                  isInView = true
                  break;
                }
              }
                return isInView && product.productGroupId  == productGroupId;
             
            }
          }
      }
      return result
    },
    productsWithTag(tag){
    var apps=[]
    if (this.getView){
      apps= this.getView.apps
    }
              
              

      const result = this.getGeneralSetting.genAiSettings.products.filter(productOf(tag,apps));
   
      function productOf(tag,apps) {
        
          return function(product) {
            
            if (apps.length==0)
           
            return (',' +product.tags + ',').includes(','+ tag + ',') 

            else{
              var isInView = false
              for (var i=0;i<apps.length;i++){
                if (apps[i]== product.productId ){
                  isInView = true
                  break;
                }
              }
                return isInView && (',' +product.tags + ',').includes(','+ tag + ',') ;
             
            }
          }
      }
      return result
    },
    subscribe(product){
      if (!product){
        console.log(product)
      }
      // if (product.templateId=='analytics'){
      //   this.$router.push({path: '/analytics-pricing'})
      // }
      // else{
      //   this.showCardPaymentPopup (true)
      // }
    },
    showCardPaymentPopup(){
         this.setShowCardPayment(true)
    }

    
  
    
  }
  }
</script>

<style scoped>
.slide-faderl-enter-active {
  transition: all 1s ease;
}
.slide-faderl-leave-active {
transition: all .20s ease;
}
.slide-faderl-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}
.slide-faderl-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-30px);
  opacity: 0;
}
.slide-fadelr-enter-active {
  transition: all 1s ease;
}
.slide-fadelr-leave-active {
transition: all .2s ease;
}
.slide-fadelr-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-30px);
  opacity: 0;
}
.slide-fadelr-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}
/* .panel {
  background: #f2f2f2;
   background: linear-gradient(180deg, #ffffff 25%, #f2f2f2 75%);
 background: linear-gradient(to bottom,  #ffffff 0%,#ffffff 21%,#f2f2f2 21%,#f2f2f2 100%);


} */
/* .paneltop{
   background: linear-gradient(to top,  #f2f2f2 0%,#f2f2f2 28%,#ffffff 28%,#ffffff 100%);
   
}
.panelcard {
  background: #f2f2f2; 
   background: linear-gradient(180deg, #ffffff 25%, #f2f2f2 75%);
  background: linear-gradient(to top,  #ffffff 0%,#ffffff 10%,#f2f2f2 10%,#f2f2f2 100%);

   

} */
.productitem{

border-top-style: none;
  border-right-style: none;
  border-bottom-style: dashed;
  border-left-style: none;
  border-width: 1px;
  border-color:rgba(0, 0, 0, 0.15)
}

.hubtitle{

line-height: 28.73px;

}

</style>
