
// import {  Analytics, AWSKinesisProvider, AWSKinesisFirehoseProvider } from 'aws-amplify';
// // import awsconfig from '@/aws-exports'; 





// // Kinesis
// //.configure(awsconfig)

// Analytics.configure({
//   AWSKinesis: {
//     region: 'us-west-2',
//   }
// })
// Analytics.addPluggable(new AWSKinesisProvider());
// Analytics.addPluggable(new AWSKinesisFirehoseProvider());


// export default function sendEvent(eventData,partitionKey, streamName ){
// try{
// Analytics.record({
//   data: eventData,
//   immediate:true,

//   partitionKey: partitionKey, // accepted for firehose provider but not used
//   streamName: streamName
// }, 'AWSKinesisProvider')
// .then((r)=> 
//   console.log(r)
// )
// .catch(e => 
//   console.log(e)
// )
// }catch(e){
//   console.log(e)
// }



// // or 'AWSKinesisFirehoseProvider'
// }


import {  Analytics , AWSKinesisProvider} from 'aws-amplify';
// import { AWSKinesisProvider } from '@aws-amplify/analytics';
// import { record } from 'aws-amplify/analytics/dist/esm/Analytics';
import awsconfig from '@/aws-exports'
Analytics.addPluggable(new AWSKinesisProvider());

// var c = awsconfig
Analytics.configure({
  AWSKinesis: {
      region: awsconfig.aws_project_region
  }
});
// Configure Amplify
//Amplify.configure(c);

// Function to send data to Kinesis
export default async function sendEvent(data, partitionKey,streamName) {
  try {
   var r =  await Analytics.record({
      name: data.name,
      attributes: data.attributes,
      partitionKey:partitionKey,
      streamName: streamName
    });
    console.log(r)
    console.log('Successfully sent data to Kinesis');
  } catch (error) {
    console.error('Error sending data to Kinesis:', error);
  }
}

// // Example usage
// const sampleData = {
//   userId: '12345',
//   event: 'button_click',
//   timestamp: new Date().toISOString()
// };

// sendToKinesis(sampleData);