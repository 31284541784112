import sendEvent from '@/analytics/kinesis'
const navigation = {
  namespaced: true,
  state: () => ({
    kinesisStream:"lifexKinesis-lifexdev",
    partitionKey:"lifex"
    
  }),
  mutations: { 
    
    
    
  },
  actions: { 
    updateKinesisStream({state}, pKinesisStream){
      state.kinesisStream = pKinesisStream
    },
    updatePartitionKey({state}, pPartitionKey){
      state.partitionKey = pPartitionKey
    },
    sendEvent({state}, pEventData){
      try{
      sendEvent(pEventData,state.partitionKey,state.kinesisStream)
      }
      catch(e){
        console.log("Error sending analytics")
        console.log(e)
      }
    }
    
  },
  getters: { 
    
  }
}

export default navigation;